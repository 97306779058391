<script lang="ts" setup>
import { Assignment } from "classes/models/assignments/assignment.model";
import { Student } from "classes/models/students/student.model";
import { SubmittedDocument } from "types/SubmittedDocument";
import { DocumentSubmissionType } from "~/types/enums/DocumentSubmissionType.enum";
import {
  DocumentGradedState,
  DocumentGradedStateLabels,
} from "~/types/enums/DocumentGradedState.enum";

const props = defineProps({
  assignment: {
    type: Object as PropType<Assignment>,
    required: true,
  },
  student: {
    type: Object as PropType<Student>,
    required: true,
  },
  submittedDocument: {
    type: Object as PropType<SubmittedDocument>,
    required: false,
  },
});

const capabilitiesService = useCapabilitiesServiceStore();
const { canGradeDocument: checkCanGrade } = storeToRefs(capabilitiesService);

const showGrade = computed(() => {
  return (
    props.submittedDocument?.state == DocumentState.graded &&
    props.submittedDocument.documentSubmissionType ==
      DocumentSubmissionType.finalDraft
  );
});

const showGradedState = computed(() => {
  return (
    props.submittedDocument?.state == DocumentState.graded &&
    props.submittedDocument?.documentGradedState &&
    props.submittedDocument?.documentSubmissionType &&
    [
      DocumentSubmissionType.keyWordOutline,
      DocumentSubmissionType.roughDraft,
    ].includes(props.submittedDocument.documentSubmissionType)
  );
});
</script>

<template>
  <tr
    :key="student.id"
    class="border-b [&>td]:p-4 hover:bg-light-default"
    :class="{
      ' bg-danger-default bg-opacity-10': submittedDocument == undefined,
    }"
  >
    <td>
      {{ student.name }}
    </td>
    <td :key="submittedDocument?.state">
      <div class="flex flex-row justify-end">
        <DocumentStateRow
          v-if="submittedDocument"
          :submitted-document="submittedDocument"
          :mode="DocumentViewMode.teacher"
          class="!justify-end"
        />
        <DocumentStatePill v-else :state="DocumentState.notImported" />
      </div>
    </td>
  </tr>
</template>

<style scoped></style>
