<script lang="ts" setup>
import { Assignment } from "classes/models/assignments/assignment.model";
import { Students } from "classes/models/students/student.model";
import { SubmittedDocuments } from "types/SubmittedDocument";

const props = defineProps({
  students: {
    type: Array as PropType<Students>,
    required: true,
  },
  assignment: {
    type: Object as PropType<Assignment>,
    required: true,
  },
  ungradedDocuments: {
    type: Array as PropType<SubmittedDocuments>,
    required: true,
  },
  gradedDocuments: {
    type: Array as PropType<SubmittedDocuments>,
    required: true,
  },
});

const studentSubmittedDocument = computed(() => {
  return (studentId: string) => {
    return (
      props.gradedDocuments.find((doc) => doc.studentId == studentId) ??
      props.ungradedDocuments.find((doc) => doc.studentId == studentId)
    );
  };
});

const numSubmittedDocuments = computed(() => {
  const gradedStudentIds = props.gradedDocuments.map((doc) => doc.studentId);
  const ungradedStudentIds = props.ungradedDocuments.map(
    (doc) => doc.studentId
  );

  const allStudentIds = [...gradedStudentIds, ...ungradedStudentIds];

  return [...new Set(allStudentIds)].length;
});

const numGradedDocuments = computed(() => {
  const studentIds = props.gradedDocuments.map((doc) => doc.studentId);
  return [...new Set(studentIds)].length;
});
</script>

<template>
  <div class="p-4 h-full">
    <BaseMultiProgressBar
      :maxValue="students.length"
      :primaryValue="numSubmittedDocuments"
      :primaryTooltip="`${numSubmittedDocuments} / ${students.length} submitted`"
      :secondaryValue="numGradedDocuments"
      :secondaryTooltip="`${numGradedDocuments} / ${students.length} graded`"
      class="w-full my-2"
    />
    <table class="bg-surface w-full">
      <ClassroomAssignmentSummaryRow
        v-for="student in students"
        :assignment="assignment"
        :student="student"
        :submittedDocument="studentSubmittedDocument(student.id!)"
        :key="student.id"
      />
    </table>
  </div>
</template>

<style scoped></style>
